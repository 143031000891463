import React, { useEffect, useState } from 'react';

import classes from './Spinner.module.scss';

interface SpinnerProps {
  type?: 'primary' | 'secondary';
  color?: 'white' | 'blue';
  overlay?: boolean;
}

const Spinner = (props: SpinnerProps) => {
  const { type, color, overlay } = props;

  const overlayClassNames = [classes.overlay];
  const spinnerClassNames = [classes.Spinner, classes[type || 'primary']];

  if (color) spinnerClassNames.push(classes[color]);

  const [animateIn, setAnimateIn] = useState(overlay);

  useEffect(() => {
    setAnimateIn(true);
  }, []);

  if (animateIn) {
    overlayClassNames.push(classes.animateIn);
  }

  return (
    <>
      {overlay && <div className={overlayClassNames.join(' ')}></div>}
      <div className={spinnerClassNames.join(' ')}></div>
    </>
  );
};

export default Spinner;

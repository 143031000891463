/* eslint-disable */
import React, { Component, Suspense } from 'react';
import ReactDOM from 'react-dom';
// import './index.scss';
import { hotjar } from 'react-hotjar';
import TagManager from 'react-gtm-module';
import * as serviceWorker from './serviceWorker';

import classes from './index.module.scss';
import Spinner from './components/UI/Spinner/Spinner';

const isIE = /* @cc_on!@ */false || !!(document as any).documentMode;
const isEdge = !isIE && !!(window as any).StyleMedia;
const App = React.lazy(() => import('./App'));

// Hotjar config
const HOTJAR_ID = process.env.REACT_APP_HOTJAR_ID;
const HOTJAR_SV = process.env.REACT_APP_HOTJAR_SV;

if (HOTJAR_ID && HOTJAR_SV) {
  const hotjarId = parseInt(HOTJAR_ID, 10);
  const hotjarSv = parseInt(HOTJAR_SV, 10);
  hotjar.initialize(hotjarId, hotjarSv);
}

// Google analytics GTM
const GTM_ID = process.env.REACT_APP_GTM_TAG;

if (GTM_ID) {
  const tagManagerArgs = {
    gtmId: GTM_ID,
  }
  TagManager.initialize(tagManagerArgs)
}

class PreLoader extends Component {
  loading = true;
  node = null;

  hideLoading = () => {
    if (!this.loading) return;
    this.loading = false;

    // Remove class without re render
    window.setTimeout(() => {
      this.node.classList.remove(classes.loading);
    }, 0);
  }

  render() {
    return (
      <div
        ref={n => this.node = n}
        className={`${classes.wrap} ${classes.loading} ${isIE || isEdge ? 'isIE' : ''}`}
      >
        <Spinner type="primary" color="blue" />
        <Suspense fallback="">
          <App hideLoading={this.hideLoading} />
        </Suspense>
      </div>
    );
  }
}

ReactDOM.render(<PreLoader />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
